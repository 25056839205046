<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <div>
      <div class='table-operator'>


        <a-form-model ref='form2' :model='form2' layout='inline'>
          <a-select v-model='form2.brandCode' style="width: 200px;margin-right: 20px" @change="changeBrand">
            <a-select-option value="">
              全部品牌公司
            </a-select-option>
            <a-select-option :value="item.brandCode" v-for="(item, index) in brandList" :key="index">
              {{item.brandName}}
            </a-select-option>
          </a-select>
          <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        </a-form-model>

        <a-button type='primary' icon='plus-circle' @click='handleNew' style="float: right;">新建目标</a-button>
      </div>

      <s-table ref='table' rowKey='id' :columns='columns' :scroll='{ x: "100%"}' :data='loadData' :pageSize="40">
        <span slot='status' slot-scope='text, record'>
          <template>
            {{getStatusStr(text)}}
          </template>
        </span>

        <span slot='action' slot-scope='text, record'>
          <template>
            <a v-if="!(record.status=='2'||record.status=='3')" @click='handleEdit(record)'>编辑</a>
            <span v-if="!(record.status=='2'||record.status=='3')" style="margin-left: 20px;">
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                                 @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </span>
          </template>
        </span>
      </s-table>
    </div>

    <a-modal :title='deliver2.form.id ? "编辑目标" : "新建目标"' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver2.visible' @cancel='()=>this.deliver2.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver2' :confirmLoading='deliver2.loading' :width="500">
      <a-form-model ref='form2' :model='deliver2.form' :rules='deliver2.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>

        <template v-if="deliver2.form.id">
          <a-descriptions :column="2">
            <a-descriptions-item :span="2">
              <span style="font-size: 25px;">{{deliver2.form.brandName}}</span>
            </a-descriptions-item>
          </a-descriptions>
        </template>
        <template v-else>
          <a-form-model-item label='品牌公司' prop='brandCode'>
          <a-select v-model='deliver2.form.brandCode'>
            <a-select-option :value="item.brandCode" v-for="(item, index) in brandList" :key="index">
              {{item.brandName}}
            </a-select-option>
          </a-select>
          </a-form-model-item>
        </template>

        <a-form-model-item label='目标顺序' prop='sort'>
          <a-input v-model='deliver2.form.sort' disabled/>
        </a-form-model-item>

        <a-form-model-item label='目标名称' prop='name'>
          <a-input v-model='deliver2.form.name' />
        </a-form-model-item>
        <a-form-model-item label='目标业绩' prop='value'>
          <a-input v-model='deliver2.form.value' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal v-model:visible="isVisible" title="提示" :destroyOnClose='true' :maskClosable='true'
             cancel-text='取消' @cancel='()=>this.isVisible=false' ok-text='关闭' @ok='()=>this.isVisible=false'>
      <div v-for="(item, index) in this.showText" :key="index">
        {{ item }}
      </div>

    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryOperators, removeOperator } from '@/api/operator'
import moment from "moment";
import {addPurpose, deletePurpose, editPurpose, getMaxSort, queryPurposeList} from "@/api/purpose";
import {queryBrand} from "@/api/cash";

const columns = [
  {
    width: 120,
    title: '品牌公司',
    dataIndex: 'brandName',
    scopedSlots: { customRender: 'brandName' }
  },
  {
    width: 80,
    title: '目标顺序',
    dataIndex: 'sort',
    scopedSlots: { customRender: 'sort' }
  },
  {
    width: 100,
    title: '目标名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  },
  {
    width: 150,
    title: '目标业绩',
    dataIndex: 'value',
    scopedSlots: { customRender: 'value' }
  },
  {
    width: 150,
    title: '开始时间',
    dataIndex: 'startTime',
    scopedSlots: { customRender: 'startTime' }
  },
  {
    width: 150,
    title: '结束时间',
    dataIndex: 'endTime',
    scopedSlots: { customRender: 'endTime' }
  },
  {
    width: 80,
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    width: 100,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  },

]

export default {
  name: 'OperatorList',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      isVisible:false,
      showText:[],
      form: {
      },
      deliver2: { visible: false,loading: false, form: {},
        rules: {
          sort: [{ required: true, message: '请填写目标顺序', trigger: 'change' }],
          name: [{ required: true, message: '请填写目标名称', trigger: 'change' }],
          value: [{ required: true, message: '请填写目标业绩', trigger: 'change' }],
          brandCode: [{ required: true, message: '请选择品牌', trigger: 'change' }],
        },
      },
      form2:{
        brandCode:"",//顶部品牌公司编码code
      },
      brandList:[],//品牌公司列表
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        let brandCode = this.form2.brandCode;
        // if(!brandCode || brandCode==""){
        //   return
        // }
        let params = Object.assign(parameter, this.queryParam,{brandCode:brandCode});
        return queryPurposeList(params).then(datum => {

          if(datum.ext1){
            let arr = datum.ext1.split("\n");

            this.showText = arr;
            this.isVisible = true;
          }
            return datum
          })
      }
    }
  },
  created() {
      this.queryBrand();
  },
  methods: {
    getStatusStr(text) {
      if(text =='1'){
        return '未开始';
      } else if(text =='2'){
        return '进行中';
      } else if(text =='3'){
        return '已完成';
      }
    },
    handleNew() {
      //获取最新序号
      getMaxSort({brandCode:this.form2.brandCode}).then(result=>{
        this.deliver2.form = Object.assign({sort:result.sort,brandCode:this.form2.brandCode});
        this.deliver2.visible = true;
      })

    },
    handleEdit(record) {
      // debugger
      this.deliver2.visible = true;
      this.deliver2.form = Object.assign({}, record);
      this.deliver2.form.type = 'edit'
    },
    handleDelete(record){
      deletePurpose({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleDeliver2(){
      this.$refs.form2.validate(result => {
        if (result) {
          // debugger;
          this.deliver2.form.loading = true;
          // this.deliver2.form.brandCode = this.form2.brandCode;
          let params = Object.assign(this.deliver2.form);
          // debugger;
          if(this.deliver2.form.type==='edit'){
            editPurpose(params).then(() => {
              this.$message.success('操作成功')
              this.deliver2.visible = false;
              this.$refs.table.refresh(true)
              this.deliver2.loading = false;
            }).catch(() => {
              this.deliver2.loading = false;
            })
          }else {
            addPurpose(params).then(() => {
              this.$message.success('操作成功')
              this.deliver2.visible = false;
              this.$refs.table.refresh(true)
              this.deliver2.loading = false;
            }).catch(() => {
              this.deliver2.loading = false;
            })
          }

        }
      })
    },
    //获取品牌公司列表
    queryBrand(){
      let month  = moment().startOf('date').format('YYYYMM');
      let params = Object.assign({pageNum:1,pageSize:100,month:month});
      // debugger;
      queryBrand(params).then(result=>{
        // debugger;
        let list = result.records;
        //顺便设置选择到的值
        this.brandList = list;

        if(list && list.length>0){
          // this.form2 = {brandCode:list[0].brandCode,brandName:list[0].brandName};
          this.handleSubmit();
        }else{
          this.$message.error("缺少品牌公司，数据无法展示！");
        }

      })
    },
    changeBrand(){
      this.handleSubmit();
    },
    handleSubmit() {
      for(let item of this.brandList){
        if(item.brandCode==this.form2.brandCode){
          this.form2 = {brandCode:item.brandCode,brandName:item.brandName};
          break;
        }
      }

      this.$refs.table.refresh(true)
      this.loading=true;
    },
  }
}
</script>
