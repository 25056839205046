import request from '@/utils/request'

export function queryPurposeList(parameter) {
  return request({
    url: '/integral/performance/goal/config/page',
    method: 'post',
    data: parameter
  })
}

export function addPurpose(parameter) {
  return request({
    url: '/integral/performance/goal/config/add',
    method: 'post',
    data: parameter
  })
}

export function editPurpose(parameter) {
  return request({
    url: '/integral/performance/goal/config/update',
    method: 'post',
    data: parameter
  })
}

export function deletePurpose(parameter) {
  return request({
    url: '/integral/performance/goal/config/delete/' + parameter.id,
    method: 'delete'
  })
}

export function getMaxSort(parameter) {
  return request({
    url: '/integral/performance/goal/config/sort/max',
    method: 'get',
    params: parameter
  })
}